<template>
    <modal class="accountPlanModel" :isShow="isShow">
        <div class="header">数据处理参数</div>
        <div class="modal-body">
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text"></span>
                    <div class="from-input">
                        <el-checkbox v-model="isStatus" label="启用数据处理功能" ></el-checkbox>
                    </div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">可隐藏的付款方式选择：</span>
                    <div class="from-input">
                        <el-select v-model="Payment_IDs" placeholder="请选择付款方式" multiple collapse-tags>
                            <el-option  v-for="item in payTypeList" :key="item.PAY_ID"  :label="item.PAY_NAME" :value="item.PAY_ID"> </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">不隐藏的收银站点账单：</span>
                    <div class="from-input">
                        <el-select v-model="Equipment_IDs" placeholder="请选择站点" multiple collapse-tags>
                            <el-option
                                v-for="item in posEquipmentList"
                                :key="item.Equipment_ID"
                                :label="item.Equipment_Name"
                                :value="item.Equipment_ID"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">自动处理方案：</span>
                    <div class="from-input">
                        <el-select v-model="doType" placeholder="请选择方案" default-first-option>
                            <el-option :value="1" label="扣减结算金额的百分比"/>
                            <el-option :value="2" label="扣减实际金额"/>
                        </el-select>
                    </div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">每天处理：</span>
                    <div class="from-input"><input-pattern type="text" pattern="money" v-model="DoNum" :min="0"/></div>
                    <div class="unit">{{doType==1?"%":"元"}}</div>
                </div>
                <div class="li-flex">
                    <span class="lable-text"></span>
                    <div class="from-input">
                        <el-checkbox v-model="isDoInvoice" label="已开发票的单据不处理" ></el-checkbox>
                    </div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">营业日报需隐藏的付款方式：</span>
                    <div class="from-input">
                        <el-select v-model="hidePaymentNames" placeholder="请选择付款方式" multiple collapse-tags>
                            <el-option  v-for="item in payTypeList" :key="item.PAY_NAME"  :label="item.PAY_NAME" :value="item.PAY_NAME"> </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()"> 确认</button>
        </div>
    </modal>
</template>

<script>
export default {
    name:"accountPlanModel",
    emits:["closeModel","confirm"],
    props:{
        isShow:Boolean,
    },
    data(){
        return {
            autoId:null,
            /**启用数据处理功能 */
            isStatus:false,
            /**已开发票的单据不处理 */
            isDoInvoice:false,
            //自动处理方案
            doType:1,
            //每天处理
            DoNum:0,
            /**付款方式数据 */
            payTypeList:[],
            /**选中的 付款方式 */
            Payment_IDs:[],
            /**站点 */
            posEquipmentList:[],
            Equipment_IDs:[],
            //时间戳
            Tmplate_Timestamps:'',
            /**营业日报需隐藏的付款方式 选中的 付款方式 */
            hidePaymentNames:this.$cacheData.get("hidePaymentNames")||[]
        }
    },
    watch:{
        //营业日报需隐藏的付款方式
        hidePaymentNames(newVal){
            this.$cacheData.set("hidePaymentNames",newVal);//存储
        }
    },
    mounted(){
        this.loadData();
    },
    methods:{
        loadData(){
            if(this.payTypeList.length==0){
                this.$cacheData.AllPayTypes().then((d)=>{
                    if(d){
                        let all=[]
                        d.forEach(item => {
                            item.PayTypes.forEach((type)=>{
                                all.push(type)
                            })
                        });
                        this.payTypeList=all;
                    }
                }).catch((e)=>{
                    this.$message.error('加载付款方式数据失败：'+e,);
                    console.log('加载付款方式数据失败：'+e);
                })
            }
            if(this.posEquipmentList.length==0){
                this.$cacheData.PosEquipments().then((d)=>{
                    if(d){
                        this.posEquipmentList=d.filter(it=>it.Sys_ID==801)
                    }
                }).catch((e)=>{
                    this.$message.error('加载站点数据失败：'+e,);
                    console.log('加载站点数据失败：'+e);
                })
            }

            const loading = this.$loading({
                text: "获取账单方案数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetAccountPlan", {}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    let info=d.ResponseBody;
                    if(info){
                        this.autoId=info.Autoid;
                        this.isStatus=info.Status;
                        this.doType=info.DoType;
                        this.DoNum=info.DoNum;
                        this.isDoInvoice=info.DoInvoice;
                        this.Tmplate_Timestamps=info.Tmplate_Timestamps;

                        this.Payment_IDs=[];
                        this.Equipment_IDs=[];
                        info.Eat_AccountConsumeList?.forEach(item=>{
                            if(item.Kind==1){
                                this.Payment_IDs.push(item.KindAutoID);
                            }else{
                                this.Equipment_IDs.push(item.KindAutoID);
                            }
                        })
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("获取账单方案数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        hide(){
            this.$emit("closeModel")
        },
        confirm(){
            let consumeList=[];
            if(this.Payment_IDs?.length>0){
                this.Payment_IDs.forEach(it=>{
                    consumeList.push({kind:1,KindAutoID:it});
                })
            }

            if(this.Equipment_IDs?.length>0){
                this.Equipment_IDs.forEach(it=>{
                    consumeList.push({kind:2,KindAutoID:it});
                })
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let data={
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Autoid:this.autoId,
                Status:this.isStatus,
                DoType:this.doType,
                DoNum:this.DoNum,
                DoInvoice:this.isDoInvoice,
                Tmplate_Timestamps:this.Tmplate_Timestamps,
                Eat_AccountConsumeList:consumeList
            }
            this.$httpAES.post("Bestech.CloudPos.SetAccountPlan",data).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    this.Autoid=d.ResponseBody.Autoid;
                    this.Tmplate_Timestamps=d.ResponseBody.Tmplate_Timestamps;
                    console.log("保存成功",d.ResponseBody);
                    this.$emit("closeModel");
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('保存失败：'+e.message);
                console.log('保存失败：',e);
            })
        }
    }
}
</script>

<style lang="scss">
@import './accountPlanModel.scss'
</style>